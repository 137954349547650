const webshopApi = 'https://spaarze-webshop-api-dev.azurewebsites.net/api';
const websiteApi = 'https://spaarze-website-api-dev.azurewebsites.net/api';
const supermarketApi = 'https://spaarze-supermarket-api-dev.azurewebsites.net/api';
const shareUrl = 'https://spaarze-facebook-dev.azurewebsites.net';
const ogoneRedirectUrl = 'https://secure.ogone.com/ncol/test/orderstandard.asp';
const imageApi = 'https://xfund-image-api-dev.azurewebsites.net/api';
export const environment = {
  production: false,
  development: true,
  projectInstanceId: 2,
  url: 'https://dev.spaarze.nl',
  apis: {
    image: imageApi
  },
  urls: {
    share: shareUrl,
    ogoneRedirect: ogoneRedirectUrl,
    supermarket: {
      graph: `${supermarketApi}/graphql`,
      stats: `${supermarketApi}/stats`,
      checkCheque: `${supermarketApi}/cheque/check`,
      entrepreneurFinancial: `${supermarketApi}/entrepreneurFinancial`,
      payment: `${supermarketApi}/payment`
    },
    webshop: {
      graph: `${webshopApi}/graphql`,
      inviteFriend: `${webshopApi}/friend/invite`,
      previewFriend: `${webshopApi}/friend/preview`,
      checkActionCode: `${webshopApi}/actioncode/check`,
      checkActionCodes: `${webshopApi}/actioncodes/check`,
      getActionCodes: `${webshopApi}/actioncodes`,
      order: `${webshopApi}/order`
    },
    website: {
      authenticate: `${websiteApi}/authenticate`,
      register: `${websiteApi}/register`,
      forgotPassword: `${websiteApi}/password/change`,
      changePassword: `${websiteApi}/password/forgot`,
      prospectInfo: `${websiteApi}/prospect/info`
    }
  },
  projectids: {
    clubfund: 1,
    spaarze: 2
  }
};
